<template>
  <v-app-bar width="100%" color="#FAFAFA">
    <v-row no-gutters align="center">
      <div
        v-html="title"
        style="
          color: var(--Black, #1b1b1b);
          font-family: 'MacPaw Fixel';
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        "
      />
      <v-row no-gutters align="center" justify="end">
        <v-col cols="2" style="text-align: right">
          <p
            style="
              color: #1b1b1b;
              font-family: 'MacPaw Fixel';
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-bottom: 0px;
            "
          >
            {{ loggedUserPTrans?.first_name }} {{ loggedUserPTrans?.last_name }}
          </p>
          <span
            style="
              color: #4b5262;
              font-family: 'MacPaw Fixel';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            "
            >{{ getUserRole() }}</span
          >
        </v-col>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div
              style="
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background: #4b5262;
                display: grid;
                place-items: center;
                margin-left: 12px;
              "
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="38px" color="#FAFAFA">mdi-account</v-icon>
            </div>
          </template>
          <div class="detailBackground" style="padding: 0px; width: 240px">
            <div
              style="padding: 12px 16px; cursor: pointer"
              @click="
                $router.push({ name: 'settingComponent' }), $vuetify.goTo(0, 0)
              "
            >
              <v-row no-gutters align="center">
                <div
                  class="settingIcon"
                  style="margin-right: 12px; margin-bottom: 2px"
                />
                <span class="menuItem">Налаштування</span>
              </v-row>
            </div>
            <div class="sectionLine" style="margin: 8px 0px"></div>
            <div style="padding: 12px 16px; cursor: pointer" @click="logout">
              <v-row no-gutters align="center">
                <div class="logoutIcon" style="margin-right: 12px" />
                <span class="menuItem">Вийти з акаунту</span>
              </v-row>
            </div>
          </div>
        </v-menu>
      </v-row>
    </v-row>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    title: {
      require: true,
    },
  },
  methods: {
    logout() {
      this.$store.commit("clearUserLogged");
      this.$router.push("/");
    },
    getUserRole() {
      switch (this.loggedUserPTrans.role) {
        case "admin":
          return "Адміністратор";
        case "manager":
          return "Менеджер";
        case "dispatcher":
          return "Диспетчер";
        case "moderator":
          return "Модаратор";
        case "guest":
          return "Гість";
      }
      return "Адміністратор";
    },
  },
  computed: {
    ...mapGetters(["loggedUserPTrans"]),
  },
};
</script>

<style scoped>
.menuItem {
  color: #4b5262;
  font-family: "MacPaw Fixel Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
</style>